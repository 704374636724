import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import TestimonialSlider from 'components/sliders/testimonial-sliders'
import ButtonBlack from 'components/button/button-black'
import Post from '../templates/post/post'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import PositiveMin from '/src/images/positive-min.png'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'
import BtnOutlined from 'components/button/outlined-button'


interface Props {
  data: IndexQueryQuery
  location: Location
}

const TestimonialsPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const office_section2_background = data.office_section2_background?.childImageSharp?.fluid
  const linda_e = data.linda_e?.childImageSharp?.fluid
  const adrien_b = data.adrien_b?.childImageSharp?.fluid
  const stevie_c = data.stevie_c?.childImageSharp?.fluid
  const no_profile_pic = data.no_profile_pic?.childImageSharp?.fluid
  const jean_p = data.jean_p?.childImageSharp?.fluid
  const doug_r = data.doug_r?.childImageSharp?.fluid
  const tony_s = data.tony_s?.childImageSharp?.fluid
  const brittany_h = data.brittany_h?.childImageSharp?.fluid
  const matthew_r = data.matthew_r?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Thank You for Contacting Us  - The Dentists At Gateway Crossing</title>
      <meta name="description" content="OUR PATIENTS LOVE US!" />
      <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Testimonials - The Dentists At Gateway Crossing" />
      <meta property="og:description" content="OUR PATIENTS LOVE US!" />
      <meta property="og:url" content="https://thedentistsatgc.com/testimonials/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="article:publisher" content="https://www.facebook.com/thedentistsatgc/" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="McCordsville Dentist" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Testimonials - The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="OUR PATIENTS LOVE US!" />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            gtag('event', 'conversion', {'send_to': 'AW-708502388/MSrUCN-DwPwBEPTG69EC'});
          `,
        }}
      />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
      <BackgroundImage
        Tag="section"
        className="hero-section-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
        alt="The Dentists at Gateway Crossing Office"
      >
        <div className ="col-lg-12" id ="color-overlay">
        </div>
        <div id="hero-section" className="text-center">
          <Container>
            <div className="col-lg-12 text-center">
              <p className="hero-text">
                THE DENTISTS AT GATEWAY CROSSING
              </p>
              <h1 className="text-light">
                THANK YOU <br />FOR CONTACTING US!
              </h1>
            </div>
            <div className="col-lg-12">
              <p className="hero-text text-light m-t-20">
                We will send you a message shortly
              </p>
            </div>
          </Container>
        </div>
      </BackgroundImage>
    </Layout>
  )
}

export default TestimonialsPage

export const query = graphql`
  query ThankYouPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    office_section2_background: file(name: { eq: "placeholder" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
